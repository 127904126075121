<template>
    <div class = 'tableSettings-container'>
        <CSDialog
                :dialogTitle="dialogTitle"
                dialogWidth="766px"
                :dialogVisible="addRoomVisibleHy"
                @onClose="addRoomVisibleHy = false"
                @onConfirm="editRoomHy"
                dialog-confirm-btn-text="保存">
            <div slot="dialog-content" class="room-form">
                <div class="field-item">
                    <label class="field-item-label">公摊汇总名称</label>
                    <div class="field-item-content">
                        <input
                                v-model="editRoomInfo.equallySharedName"
                                type="text"
                                placeholder="限20个字"
                                maxlength="20"
                        />
                    </div>
                </div>

                <div class="field-item" style="margin-bottom: 0px">
                    <label class="field-item-label">计费起止日期</label>
                    <div class="field-item-content">
                        <div>
                            <CSSelect i-width="40px" style="width: 180px; margin-right: 0;height: 40px;">
                                <el-date-picker
                                        type="datetime"
                                        placeholder="请选择开始时间"
                                        v-model="editRoomInfo.collectBillStartDate"
                                        style="width: 170px;font-size: 20px"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        prefix-icon="el-icon-time"
                                        :editable="false"
                                >
                                </el-date-picker>
                            </CSSelect>
                            <span class="inline-gray"> </span>
                            <CSSelect i-width="40px" style="width: 180px;height: 40px">
                                <el-date-picker
                                        type="datetime"
                                        placeholder="请选择结束时间"
                                        v-model="editRoomInfo.collectBillEndDate"
                                        style="width: 190px;font-size: 20px"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        prefix-icon="el-icon-time"
                                        :editable="false"
                                >
                                </el-date-picker>
                            </CSSelect>
                        </div>

                    </div>
                </div>

                <div class="field-item" style="color: #999999;">
                    <label class="field-item-label"></label>
                    <div class="field-item-content">
                        <svg class="icon" aria-hidden="true" style="font-size: 20px;">
                            <use xlink:href="#icon-menua-zu92"></use>
                        </svg>
                        <span style="font-size: 20px;">
                            私区水费用量将根据该日期统计。
                        </span>

                    </div>
                </div>

                <div class="field-item jsF">
                    <label class="field-item-label" >关联位置</label>
                    <div class="field-item-content">
                        <CSSelect height="40px" iWidth="36px">
                            <select
                                    v-model="editRoomInfo.buildingId"
                                    @change="changeBuilding"
                                    :style="true?`background:rgba(170, 170, 170,0.1);color: #999999`:`color: #999999`"
                            >
                                <option value="">请选择</option>
                                <!--                                <option-->
                                <!--                                        v-for="building in buildings"-->
                                <!--                                        :key="building.code"-->
                                <!--                                        :value="building.code"-->
                                <!--                                >-->
                                <!--                                    {{ building.name }}-->
                                <!--                                </option>-->
                            </select>
                        </CSSelect>
                    </div>
                </div>

                <div class="field-item jsF">
                    <label class="field-item-label" >计费截止日总表读数</label>
                    <div class="field-item-content">
                        <CSSelect height="40px" iWidth="36px">
                            <select
                                    v-model="editRoomInfo.buildingId"
                                    @change="changeBuilding"
                                    :style="false?`background:rgba(170, 170, 170,0.1);color: #999999`:`color: #999999`"
                            >
<!--                                                                <option value="">请选择</option>-->
<!--                                                                <option-->
<!--                                                                        v-for="building in buildings"-->
<!--                                                                        :key="building.code"-->
<!--                                                                        :value="building.code"-->
<!--                                                                >-->
<!--                                                                    {{ building.name }}-->
<!--                                                                </option>-->
                            </select>
                        </CSSelect>
                    </div>
                </div>

                <div class="field-item jsF">
                    <label class="field-item-label" >园区总用水量</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >私区总用水量</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >公摊总用水量</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >公摊水价</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >公摊水费总额</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >水费公摊总面积</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >单位面积公摊费</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label" >预计生成账单数</label>
                    <div class="field-item-content">
                        222
                    </div>
                </div>

                <div>
                    <svg class="icon" aria-hidden="true" style="font-size: 20px;">
                        <use xlink:href="#icon-menua-zu92"></use>
                    </svg>
                    <span style="font-size: 20px;color: #999999">
                           账单产生后不可撤销，请仔细核对填写内容。
                        </span>
                </div>
            </div>
        </CSDialog>
    </div>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    export default {
        name: "TableSettings",
        props:{
            dialogTitle:{
                default:'电表设置'
            }
        },
        components:{
            CSDialog
        }
    }
</script>

<style scoped lang="stylus">
    .room-form {
        padding: 27px 30px 10px;

    .field-item {
        margin-bottom: 22px;
        font-size: 0;

    &-label {
         font-size: 24px;
         text-align: right;
         width: 208px;
         margin-right: 40px;
         display: inline-block;
         vertical-align: middle;
         line-height: 1;
         margin-bottom: 0;
     }

    &-content {
         display: inline-block;
         vertical-align: middle;
         font-size: 24px;

    input {
        width: 400px;
        height: 40px;
        text-indent: 5px;
        border-radius: 4px;
        border: 1px solid #979797;

    &::placeholder {
         color: #999;
     }
    }

    select {
        width: 220px;
        text-indent: 5px;
    }
    }
    }

    .tips {
        color: #999;
        font-size: 20px;
    }

    .field-font {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 33px;
        color: #999999;
    }
    }

    .location-search {
        width: 100%;
        display: flex;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 30px;
        height: 40px;
        box-sizing: border-box;
    }

    .font {
        font-size: 20px;
        vertical-align: middle;
        margin-left: 30px;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    }

    .selectDive {
        padding: 0;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        display: inline-block;
        border-radius: 3px;
        margin-right: 5px;
        position: relative;
        background-image: "../../assets/未选中.png"
    }

    input[type='checkbox'] {
        width: 0px !important
        display inline-block
        opacity 1
        margin-right 25px
        height 20px !important
    }

    .roomli {
        display flex
        align-items center
    }

    .roomMove {
        cursor pointer
    }
    .field-item-label{
        width 220px !important
    }
</style>
<style>
    .summarCollection-container .el-input__inner {
        padding-right: 10px !important;
    }

    .summarCollection-container .el-icon-caret-bottom {
        padding-top: 4px !important;
    }
    .jsF .el-icon-caret-bottom{
        padding-top: 0px !important;
    }
</style>